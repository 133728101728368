import { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FeedbackModal } from './components/mplat/Modals/FeedbackModal';


function AppFooter() {
    const [accessRequested, setAccessRequested] = useState(false);

    return <footer className="footer">
        <FeedbackModal isOpen={accessRequested} onClose={() => setAccessRequested(false)} />

        <div className="footer__inner">
            <nav className="footer__nav">
                <ul>
                    <li>
                        <a href="#feedback" onClick={(e) => {
                            e.preventDefault();
                            setAccessRequested(true);
                        }}>
                            <Trans>Feedback</Trans></a>
                    </li>
                    <li>
                        <Link to="/contacts"><Trans>Contacts</Trans></Link>
                    </li>
                </ul>
            </nav>
            <div className="footer__copy">© <Trans>Contacts msk-ix</Trans> {new Date().getFullYear()}</div>
        </div>
    </footer>
}

export default AppFooter;
