
interface FeatureItem {
    icon: string,
    label: string,
    content: string
}

interface HomeFeaturesProps {
    badges?: string[],
    children?: React.ReactNode;
}

interface HomeFeatureItemProps {
    item: FeatureItem,
}

export function HomeFeatureItem({ item }: HomeFeatureItemProps) {
    return (
        <article className="home-features__item">
            <p className="home-features__item-image">
                <img src={item.icon} alt="" />
            </p>
            <h2 className="home-features__item-heading">{item.label}</h2>
            <p className="home-features__item-copy" dangerouslySetInnerHTML={{__html: item.content}} />
        </article>
    )
}

function HomeFeatures({ children }: HomeFeaturesProps) {
    return (
        <section className="home-features">
            {children}
        </section>
    )
}

export default HomeFeatures;