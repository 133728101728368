import log from 'loglevel';
import React from 'react';

interface HasError {
    hasError: boolean,
    error?: Error
}

class ErrorBoundary extends React.Component {
    state: HasError;
    // eslint-disable-next-line
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): HasError {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { hasError: true, error };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    componentDidCatch(error: any, errorInfo: any) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        log.error(error.message);
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return (
                <div>
                    <h1 className="text-pink-500">
                        Произошла ошибка, попробуйте обновить страницу.
                        {' '}
                        <br />
                    </h1>
                    <h2 className="text-pink-600">
                        {this.state.error?.name}
                        :
                        {' '}
                        {this.state.error?.message}
                    </h2>
                </div>
            );
        }
        // eslint-disable-next-line
        return (this.props as any).children;
    }
}

export default ErrorBoundary;
