// import Intro from '../../../assets/img/home/intro-image.svg';
import Intro from '../../../assets/img/home/pros-image.svg';


interface HomeIntroProps {
    label: string,
    badges: string[],
}

function HomeIntro({ label, badges }: HomeIntroProps) {
    return (
        <section className="home-intro">
            <div className="home-intro__copy">
                <h1 className="home-intro__heading" dangerouslySetInnerHTML={{ __html: label }}></h1>
                <ul className="home-intro__pros">
                    {badges.map(badge => <li key={badge} dangerouslySetInnerHTML={{ __html: badge }}></li>)}
                </ul>
            </div>
            <div className="home-intro__image">
                <img src={Intro} alt="" />
            </div>
        </section>
    )
}

export default HomeIntro;