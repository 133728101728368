import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppMain from './AppMain';
import { ReactComponent as CatalogLogo } from './assets/svg/Catalog.svg';
import { ReactComponent as ShowcaseLogo } from './assets/svg/CatalogMin.svg';
import { ReactComponent as ConsumerLogo } from './assets/svg/Consumer.svg';
import { ReactComponent as NotificationLogo } from './assets/svg/Notify.svg';
import { ReactComponent as ReqLogo } from './assets/svg/PermRequest.svg';
import { ReactComponent as ToolsLogo } from './assets/svg/Tools.svg';
import { EMPTY_ROLE, ROLE } from './components/common';
import './css/common.css';
import './css/style.min.css';
import { useAuth, useBodyClass, useHtmlClass, useRole } from './hooks';
import './langs/i18n';
import { setRole, useLazyGetSummaryQuery } from './services/api';
import { BASE_URL } from './services/reauth';
import { useLazyGetCurrentAccountQuery, useLazyGetProfileQuery, useLazyGetRegistrationsQuery } from './services/users_v1';


remote.apply(log, {
    url: BASE_URL + 'logger/',
    method: 'POST',
    timeout: 0,
    interval: 60000,
    level: 'warn',
    backoff: {
        multiplier: 10,
        jitter: 0.1,
        limit: 30000,
    },
    capacity: 500,
    stacktrace: {
        levels: ['trace', 'warn', 'error'],
        depth: 5,
        excess: 0,
    },
    format: remote.json
});

export interface CustomMenuItem {
    link: string,
    avail?: string[]
    id?: string;
    label?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    url?: string;
    items?: CustomMenuItem[] | CustomMenuItem[][];
    expanded?: boolean;
    disabled?: boolean;
    visible?: boolean;
    target?: string;
    separator?: boolean;
    style?: object;
    className?: string;
    command?(): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    noend?: boolean;
}

function App() {
    const [summaryPollingInterval, setSummaryPollingInterval] = useState(0);

    const [fetchProfile] = useLazyGetProfileQuery();
    const [fetchUserAccount] = useLazyGetCurrentAccountQuery();
    const [fetchSummary, { data: summary }] = useLazyGetSummaryQuery({pollingInterval: summaryPollingInterval});
    const [fetchApproveRequests, { data: newUsers }] = useLazyGetRegistrationsQuery();

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const auth = useAuth();
    const role = useRole();
    const { t } = useTranslation();

    const [menuItems, setMenuItems] = useState<CustomMenuItem[]>([]);
    const [bodyClasses, setBodyClasses] = useState<string[]>([]);

    useEffect(() => {
        if (auth.account) {
            // начинаемм опросы только для авторизованного пользователя
            setSummaryPollingInterval(15000);
            fetchSummary();
            fetchProfile();
            fetchUserAccount();
        } else {
            setSummaryPollingInterval(0);
        }
    }, [auth.account, fetchSummary, fetchUserAccount, fetchProfile]);

    useEffect(() => {
        if (auth.account && role.label === ROLE.SITE_ADMIN) {
            fetchApproveRequests();
        }
    }, [auth.account, fetchApproveRequests, role.label]);

    // глобальная раскраска зависящая от типа аккаунта (белый - поставщик, черный - потребитель, желтый - админ, голубой - модератор/партнер)
    useEffect(() => {
        const tmpClasses: string[] = [];
        if (auth.account) {
            const consumerRoles = [ROLE.CONSUMER_ADMIN, ROLE.CONSUMER_AUDITOR, ROLE.CONSUMER_CONTENT_MANAGER, ROLE.CONSUMER_ENGINEER, ROLE.CONSUMER_OWNER];
            if (!consumerRoles.includes(role.label) && (!location.pathname.startsWith('/content/view/'))) {
                tmpClasses.push('page-producer');
                if (role.label === ROLE.SITE_ADMIN) {
                    tmpClasses.push('access-admin');
                } else if ([ROLE.PARTNER_MODERATOR_ADMIN, ROLE.PARTNER_MODERATOR_MODERATOR, ROLE.PARTNER_MODERATOR_OWNER].includes(role.label)) {
                    tmpClasses.push('access-mod');
                }
            } else {
                tmpClasses.push('page-subscriber');
            }
        } else {
            dispatch(setRole(EMPTY_ROLE));
            // if (location.pathname === '/') {
            tmpClasses.push('page-home');
            // }
        }
        setBodyClasses(tmpClasses);
    }, [location.pathname, auth.account, dispatch, role.label]);

    useBodyClass(bodyClasses);

    const [isTop, setIsTop] = useState(window.scrollY === 0);
    useHtmlClass(!isTop ? ['_header-fixed'] : []);

    const handleNavigation = useCallback(() => {
        setIsTop(window.scrollY === 0);
    }, []);

    useEffect(() => {
        setIsTop(window.scrollY === 0);
        window.addEventListener('scroll', handleNavigation);

        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);

    const items = useMemo(() => [
        {
            label: t('Showcase'),
            icon: <ShowcaseLogo />,
            command: () => navigate('/showcase'),
            link: '/showcase',
            avail: [ROLE.CONSUMER_ADMIN, ROLE.CONSUMER_AUDITOR, ROLE.CONSUMER_CONTENT_MANAGER, ROLE.CONSUMER_ENGINEER, ROLE.CONSUMER_OWNER]
        },
        {
            label: t('Requests'),
            icon: <ReqLogo />,
            command: () => navigate('/requests'),
            data: newUsers?.results.length,
            link: '/requests',
            avail: [ROLE.SITE_ADMIN]
        },
        {
            label: t('Requests'),
            icon: <ReqLogo />,
            data: Number(summary?.results.access_requests.opened) + Number(summary?.results.access_requests.pending),
            command: () => navigate('/access'),
            link: '/access',
            noend: true,
            avail: [ROLE.CONSUMER_OWNER, ROLE.PROVIDER_OWNER, ROLE.CONSUMER_OWNER]
        },
        {
            label: t('Notifications'),
            icon: <NotificationLogo />,
            command: () => navigate('/notifications'),
            data: summary?.results.notifictions.new,
            link: '/notifications',
            avail: [ROLE.CONSUMER_OWNER, ROLE.PROVIDER_OWNER, ROLE.SITE_ADMIN, ROLE.MODERATOR]
        },
        {
            label: t('Accounts'),
            icon: <ConsumerLogo />,
            command: () => navigate('/accounts'),
            link: '/accounts',
            avail: [ROLE.SITE_ADMIN]
        },
        {
            label: t('Users'),
            icon: <ConsumerLogo />,
            command: () => navigate('/users'),
            link: '/users',
            avail: [ROLE.SITE_ADMIN]
        },
        {
            label: t('Consumers'),
            icon: <ConsumerLogo />,
            command: () => navigate('/consumers'),
            link: '/consumers',
            avail: [ROLE.PROVIDER_OWNER]
        },
        {
            label: t('Tools'),
            icon: <ToolsLogo />,
            command: () => navigate('/tools'),
            data: summary?.results.tools.transcoding_requests,
            link: '/tools',
            avail: [ROLE.CONSUMER_OWNER, ROLE.PROVIDER_OWNER, ROLE.SITE_ADMIN, ROLE.MODERATOR]
        },
        {
            label: t('Catalog'),
            icon: <CatalogLogo />,
            command: () => navigate('/content'),
            link: '/content',
            avail: [ROLE.PROVIDER_OWNER, ROLE.SITE_ADMIN, ROLE.MODERATOR]
        },
    ], [navigate, newUsers?.results.length, summary, t]);

    // список элементов верхнего меню
    useEffect(() => {
        if (auth.account) {
            if (role.label !== ROLE.UNKNOWN) {
                setMenuItems(items.filter((item) => {
                    return !item.avail || item.avail?.includes(role.label);
                }));
            }
        } else {
            setMenuItems([]);
        }
    }, [role.label, items, auth.account]);

    return <>
        <AppHeader items={menuItems} />
        <AppMain />
        <AppFooter />
    </>
}

export default App;
