import { RefObject, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { RootState } from '../data/store';
import { Language } from "../services/api";

interface inputHook {
    value: string,
    onChange: (e: string | number | React.ChangeEvent<HTMLInputElement> | undefined) => void,
};

type InputResult = [inputHook, () => void];

export const useInput = (initialValue: string): InputResult => {
    const [value, setValue] = useState(initialValue);
    return [
        {
            value,
            onChange: (e: string | number | React.ChangeEvent<HTMLInputElement> | undefined) => {
                const target = (e as React.ChangeEvent<HTMLInputElement>)?.target
                if (target) {
                    setValue(target.value);
                } else {
                    setValue((e as string) || '');
                }
            }
        },
        () => setValue(initialValue)
    ];
};

export const selectCurrentUserRole = (state: RootState) => state.role;
export const selectCurrentUserAccount = (state: RootState) => state.acc;


export const useRole = () => {
    const role = useSelector(selectCurrentUserRole);
    return useMemo(() => role, [role]);
}

export const useAcc = () => {
    const acc = useSelector(selectCurrentUserAccount);
    return useMemo(() => acc, [acc]);
}

export const selectCurrentUser = (state: RootState) => state.auth;

export const useAuth = () => {
    const auth = useSelector(selectCurrentUser);
    const refreshToken = auth.refreshToken || localStorage.refreshToken;
    const accessToken = auth.accessToken || localStorage.accessToken;
    const user = auth.user || localStorage.user;
    const account = auth.account || localStorage.account;
    const suUser = auth.suUser || localStorage.suUser;
    const suRefresh = auth.suRefresh || localStorage.suRefresh;

    // if (auth.user !== undefined) localStorage.user = auth.user;
    // if (auth.account !== undefined) localStorage.account = auth.account;
    // if (auth.refreshToken !== undefined) localStorage.refreshToken = auth.refreshToken;
    // if (auth.accessToken !== undefined) localStorage.accessToken = auth.accessToken;
    // if (auth.suUser !== undefined) localStorage.suUser = auth.suUser;
    // if (auth.suRefresh !== undefined) localStorage.suRefresh = auth.suRefresh;

    return useMemo(() => ({ user: user, account, refreshToken: refreshToken, accessToken: accessToken, suUser: suUser, suRefresh: suRefresh }), [user, account, accessToken, refreshToken, suRefresh, suUser]);
}

export function useOutsideAlerter(ref: any, setIsExpand: any, sibling?: any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (sibling) {
                    if (sibling.current && !sibling.current.contains(event.target)) {
                        setIsExpand(false);
                    }
                } else {
                    setIsExpand(false);
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setIsExpand, sibling]);
}

export function useOnScreen(ref: RefObject<HTMLElement>) {

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), []);


    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current)
            return () => observer.disconnect();
        }
    }, [observer, ref]);

    return isIntersecting;
}

export function useLanguage() {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language as Language);

    useEffect(() => {
        setLang(i18n.language as Language);
    }, [i18n.language]);

    return lang;
}

const addBodyClass = (className: string) => document.body.classList.add(className);
const removeBodyClass = (className: string) => document.body.classList.remove(className);

export function useBodyClass(className: string[] | string) {
    useEffect(
        () => {
            // Set up
            (className instanceof Array) ? className.map(addBodyClass) : addBodyClass(className);

            // Clean up
            return () => {
                (className instanceof Array)
                    ? className.map(removeBodyClass)
                    : removeBodyClass(className);
            };
        },
        [className]
    );
}

const addHtmlClass = (className: string) => document.documentElement.classList.add(className);
const removeHtmlClass = (className: string) => document.documentElement.classList.remove(className);

export function useHtmlClass(className: string[] | string) {
    useEffect(
        () => {
            // Set up
            (className instanceof Array) ? className.map(addHtmlClass) : addHtmlClass(className);

            // Clean up
            return () => {
                (className instanceof Array)
                    ? className.map(removeHtmlClass)
                    : removeHtmlClass(className);
            };
        },
        [className]
    );
}