import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import account from './account.json';
import component from './component.json';
import dashboard from './dashboard.json';
import group from './group.json';
import modal from './modal.json';
import order from './order.json';
import profile from './profile.json';
import tv from './tv.json';


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: localStorage.i18nextLng || 'ru',
        // we init with resources
        resources: {
            en: {
                account: account.en,
                component: component.en,
                dashboard: dashboard.en,
                profile: profile.en,
                modal: modal.en,
                tv: tv.en,
                group: group.en,
                order: order.en,
                translations: {
                    "provider_owner": "Provider owner",
                    "consumer_auditor": "Consumer auditor",
                    "Enter to account": "Enter to account",
                    'Accounts': 'Accounts',
                    'My accounts': 'My accounts',
                    'Ask our support': 'Ask our support',
                    'Mediabaza': 'Mediabaza',
                    'Comment': 'Comment',
                    'Next': 'Next',
                    'Cancel': 'Cancel',
                    'Transcode': 'Transcode',
                    'Transcoded': 'Transcoded',
                    'Download transcoded': 'Download transcoded',
                    'Enter a techinfo': 'Enter a techinfo',
                    'Download all': 'Download all',
                    'Email': 'Email',
                    'Enter Email': 'Enter Email',
                    'Enter password': 'Enter password',
                    'Passwords do NOT match': 'Passwords do NOT match',
                    'Global marketplace <br>to buy and sell media content': 'Global marketplace <br>to buy and sell media content',
                    'Unique content': 'Unique content',
                    'Piracy Protection': 'Piracy protection',
                    'Free access to basic functions': 'Free access to basic functions',
                    'Affordable price': 'Affordable price',
                    'User friendly support': 'User friendly support',
                    'Content Search': 'Content Search',
                    'Unique catalog of downloadable content and TV channels for easy search and order': 'Unique catalog of downloadable content for easy search and order',
                    'Licensing Rights': 'Licensing Rights',
                    'Safe transactions between a licensor and a licensee. Highly automated processes': 'Safe transactions between a licensor and a licensee. Highly automated processes',
                    'Delivery': 'Delivery',
                    'Reliable content delivery of heavy-loaded video content in innovative formats VR, 4К, 8К, HDR': 'Reliable content delivery of heavy-loaded video content in innovative formats VR, 4К, 8К, HDR',
                    'Updating': 'Updating',
                    '<br />catalog of streaming services': '<br />catalog of streaming services',
                    'Distributing': 'Distributing',
                    '<br />ad-videos to the regional network': '<br />ad-videos to the regional network',
                    'Uploading': 'Uploading',
                    'movies to multiplex movie theaters': 'movies to multiplex movie theaters',
                    'Setting content': 'Setting content',
                    'for TV channels': 'for TV channels',
                    'Cloud platform to increase the efficiency of interactions between professional participants in the media market': 'Cloud platform to increase the efficiency of interactions between professional participants in the media market',
                    'For Sellers': 'For Sellers',
                    'Sell Сontent': 'Sell Сontent',
                    'Single entry point to reach your customers': 'Single entry point to reach your customers',
                    'Safe storage of content archive': 'Safe storage of content archive',
                    'Fast delivery of heavy 4K content': 'Fast delivery of heavy 4K content',
                    'Content distribution control': 'Content distribution control',
                    'Lead generation': 'Lead generation',
                    'For Buyers': 'For Buyers',
                    'Buy Сontent': 'Buy content',
                    'Easy to use catalog to search for content from all over the world': 'Easy to use catalog to search for content from all over the&nbsp;world',
                    'Easy content ordering': 'Easy content ordering',
                    'Video transcoding into any format': 'Video transcoding into any format',
                    'Downloading using speed protocol': 'Downloading using speed protocol',
                    'Metadata is included': 'Metadata is included',
                    'Get free access to try out the platform': 'Get free access to try out the platform',
                    'Get Access': 'Get Access',
                    'Feedback': 'Feedback',
                    'Contacts': 'Contacts',
                    'To showcase': 'To showcase',
                    'Download': 'Download',
                    'Download again': 'Download again',
                    'Create': 'Create',
                    'Send': 'Send',
                    'Content unit': 'Content unit',
                    'TV channel': 'TV channel',
                    'Create a new card': 'Create new card',
                    'Create a content unit': 'Create content unit',
                    'Create a tv unit': 'Create TV channel unit',
                    '+ Upload files': '+ Upload files',
                    'Enter a title': 'Enter a title',
                    'Enter a name': 'Enter a name',
                    'Enter license number': 'Enter license number',
                    'Title': 'Title',
                    'optional': 'optional',
                    'required': 'required',
                    'Load cover': 'Load cover',
                    'Load demo': 'Load demo',
                    'Choose a category': 'Choose a category',
                    'Fill in the information': 'Fill in the information',
                    'Choose a country': 'Choose a country',
                    'Choose a language': 'Choose a language',
                    'Choose a genre': 'Choose a genre',
                    'Choose a collection': 'Choose a collection',
                    'Country of Origin': 'Country of Origin',
                    'Voice language': 'Voice language',
                    'Genre': 'Genre',
                    'Subtitle': 'Subtitle',
                    'Choose a year': 'Choose a year',
                    'Year of issue': 'Year of issue',
                    'Description': 'Description',
                    'Enter a description': 'Enter a description',
                    'Quality': 'Quality',
                    'Choose a quality': 'Choose a quality',
                    'Choose an accessibility mode': 'Choose an accessibility mode',
                    'To view': 'To view',
                    'To download': 'To download',
                    'Choose a role': 'Choose a role',
                    'Choose a specialization': 'Choose a specialization',
                    'Choose a customers': 'Choose a customers',
                    'Choose a customers to view': 'Choose a customers to view',
                    'Choose a customers to download': 'Choose a customers to download',
                    'Offer consumers': 'Offer consumers',
                    'All': 'All',
                    'List': 'List',
                    'Nobody': 'Nobody',
                    'Drag file here': 'Drag file here',
                    'Drag files here': 'Drag files here',
                    'or select on computer': 'or select on computer',
                    'Supported file formats mp4, webm и ogg': 'Supported file formats mp4, webm и ogg',
                    'Page not found': 'Page not found',
                    'You can go ': 'You can go ',
                    'back': 'back',
                    ' or go to the ': ' or go to the ',
                    'showcase': 'showcase',
                    'Sign': 'Sign',
                    'Log In': 'Log In',
                    'Sign in': 'Log In',
                    'Sign Up': 'Sign Up',
                    'Forgot password': 'Forgot password',
                    'Forgot your password': 'Forgot your password',
                    'Register': 'Register',
                    'Tools': 'Tools',
                    'Load into xls': 'Load into xls',
                    'Message is required': 'Message is required',
                    'Email is required': 'Email is required',
                    'Code is required': 'Code is required',
                    'Invalid code': 'Invalid code',
                    'Files': 'Files',
                    'Load error': 'Load error',
                    'Delete request error': 'Delete request error',
                    'Change permissions error': 'Change permissions error',
                    'Save failed': 'Save failed',
                    'Delete failed': 'Delete failed',
                    'This password is too short. It must contain at least 8 characters.': 'This password is too short. It must contain at least 8 characters.',
                    'This password is too common.': 'This password is too common.',
                    'Delivered': 'Delivered',
                    'Handled': 'Handled',
                    'MY': 'MY',
                    'ALL': 'ALL',
                    'CUSTOM': 'CUSTOM',
                    'NOTHING': 'NOTHING',
                    'Notification list': 'Notification list',
                    'View Permissions': 'View Permissions',
                    'Download Permissions': 'Download Permissions',
                    'Novelty': 'Novelty',
                    'Admin dashboard': 'Admin dashboard',
                    'Consumer dashboard': 'Consumer dashboard',
                    'Provider dashboard': 'Provider dashboard',
                    'Add': 'Add',
                    'Edit': 'Edit',
                    'Delete': 'Delete',
                    'Deleted': 'Deleted',
                    'Success': 'Success',
                    'Error': 'Error',
                    'Save': 'Save',
                    'View': 'View',
                    'Back': 'Back',
                    'Check': 'Check',
                    'Refresh': 'Refresh',
                    'Confirm': 'Confirm',
                    'Settings': 'Settings',
                    'Support': 'Support',
                    'FAQ': 'FAQ',
                    'Account': 'Account',
                    'Tariff': 'Tariff',
                    'Statistics': 'Statistics',
                    'Help': 'Help',
                    'Downloads': 'Downloads',
                    'Code': 'Code',
                    'Status': 'Status',
                    'Quick add': 'Quick add',
                    'No Data': 'No Data',
                    'Set user': 'Set user',
                    'Off': 'Off',
                    'Yes': 'Yes',
                    'No': 'No',
                    'Token': 'Token',
                    'Grant': 'Grant',
                    'Reject': 'Reject',
                    'Owner': 'Owner',
                    'Cover': 'Cover',
                    'Order': 'Order',
                    'Catalog': 'Catalog',
                    'Notifications': 'Notifications',
                    'To list': 'To list',
                    'Access requests': 'Access requests',
                    'Access request': 'Access request',
                    'Request permission': 'Request permission',
                    'Repeat password': 'Repeat password',
                    'Enter email for verification': 'Enter email for verification',
                    'Enter code for': 'Enter code for',
                    'Send again': 'Send again',
                    'Requests for registration': 'Requests for registration',
                    'confirmPre': 'Confirm pre-registration form for <1>{{email}}</1>',
                    'The code is valid for': 'The code is valid for',
                    'second': 'second',
                    'Re-request code after': 'Re-request code after',
                    'Reset password': 'Reset password',
                    'Change password': 'Change password',
                    'Activate': 'Активировать',
                    'Activate account': 'Activate account',
                    'Email already registered': 'Email already registered',
                    'Confirmation code is wrong': 'Confirmation code is wrong',
                    'Old password': 'Old password',
                    'New password': 'New password',
                    'Content unit information': 'Content unit information',
                    'Token is invalid or expired': 'Token is invalid or expired',
                    'Password successfully changed': 'Password successfully changed',
                    'Wait for the letter to the specified email and follow the link': 'Wait for the letter to the specified email and follow the link',
                    "Enter your username and password or apply for registration": "Enter your username and password or apply for registration",
                    'To complete the registration, wait for confirmation from the administrator and follow the link in the letter sent to you': 'To complete the registration, wait for confirmation from the administrator and follow the link in the letter sent to you',
                    'AfterRegister1': 'Thank you for filling out the application to connect to our service.',
                    'AfterRegister2': 'The service is in test mode, so the review period may be up to 2 business days.',
                    'AfterRegister3': 'If it is necessary to clarify the data, we will contact you by phone specified in the application.',
                    'AfterRegister4': 'We will notify you of the outcome of your application by e-mail.',
                    'Contact Support': 'Contact Support',
                    "Main": 'Main',
                    'Fill out the pre-registration form for': 'Fill out the pre-registration form for',
                    "Users": "Users",
                    "User": "User",
                    "Firstname": 'Firstname',
                    "Surname": 'Surname',
                    "Company": 'Company',
                    "Phone": 'Phone',
                    "Role": 'Role',
                    "administrators": 'Administrators',
                    "Consumer": 'Consumer',
                    "Consumers": 'Consumers',
                    "Subscribers": 'Subscribers',
                    "consumers": 'Consumer',
                    "Provider": 'Provider',
                    "providers": 'Provider',
                    "Moderator": 'Moderator',
                    "Moderators": 'Moderators',
                    "moderators": 'Moderator',
                    "moderator": 'Moderator',
                    'Is active': 'Is active',
                    'Is locked': 'Is locked',
                    'Joined': 'Joined',
                    'Last login': 'Last login',
                    'Profile': 'Profile',
                    'Content': 'Content',
                    'Requests': 'Requests',
                    'Orders': 'Orders',
                    'Login': 'Login',
                    'Password': 'Password',
                    'Avatar': 'Avatar',
                    'title or title_eng must be specified': 'title or title_eng must be specified',

                    'Apply for registration': 'Apply for registration',
                    'Trailer': 'Trailer',
                    'Trailer exists': 'Trailer exists',
                    'Type': 'Type',
                    'Collection': 'Collection',
                    'Year': 'Year',
                    'Language': 'Language',
                    'Country': 'Country',
                    'Techinfo': 'Techinfo',
                    'Links': 'Links',
                    'Tags': 'Tags',
                    'List of content': 'List of content',
                    'IMDb': 'IMDb',
                    'Kinopoisk': 'Kinopoisk',
                    '{0} selected': '{0} selected',
                    'Image type not supported': 'Image type not supported',
                    'Content is not available': 'Content is not available',
                    'No rights to view': 'No rights to view',
                    'Showcase': 'Showcase',
                    'Consumers list': 'Consumers list',
                    'Search': 'Search',
                    'Permited': 'Permited',
                    'Not permited': 'Not permited',
                    'Meta': 'Meta',
                    'Show filter': 'Show filter',
                    'Hide filter': 'Hide filter',
                    'Nothing found': 'Nothing found',
                    'Messages': 'Messages',
                    'Message': 'Message',
                    'Download manager': 'Download manager',
                    'Media': 'Media',
                    'Upload': 'Upload',
                    "block": "Block",
                    "unblock": "Unblock",
                    'ORPHAN_CONTENT': 'To append content, first save the name of the card',
                    'ORPHAN_TRAILER': "To append trailer, first save the name of the card",
                    'ORPHAN_COVER': 'To append cover, first save the name of the card',
                    'Orders filter all': 'All',
                    'Orders filter new': 'New',
                    'Orders filter pending': 'Pending',
                    'Orders filter granted': 'Granted',
                    'Orders filter rejected': 'Rejected',
                    'Orders filter archived': 'Archive',
                    'Orders filter date': 'Order date',

                    'FAQ title': 'Frequently Asked Questions (FAQ)',
                    'Feedback your message': 'Your message',
                    'Feedback put message': 'Put your message',
                    'Feedback email': 'Email',
                    'Feedback put email': 'Put your email',
                    'Feedback sent': 'Request was sent',
                    'Feedback hide': 'Close',

                    'Contacts msk-ix': 'MSK-IX JSC',
                    'Contacts msk-ix long': 'MSK-IX Computer Network Interaction Center Joint-Stock Company',
                    'Contacts connect': 'Connect and support',
                    'Contacts press': 'PR',
                    'Contacts administration': 'Administration',
                    'Header menu support': 'Support',
                    'SHORT_SECOND': 's',
                    'Date': 'Date',
                    'Demo': 'Demo',
                    'You have unsaved canges in content card. Continue?': 'You have unsaved canges in content card. Continue?',
                    'Ordered': 'Ordered',
                    'Rejected': 'Rejected',
                    'Granted': 'Granted',
                    'Change subscriber status error': 'Change subscriber status error',
                    'Back from view mode': 'Back from view mode',
                    'Meta': 'Meta',
                    'Category': 'Category',
                    'Territory': 'Territory',
                    'Type of rights': 'Type of rights',
                    'Accessibility mode': 'Accessibility mode',
                    'Accessibility mode for view': 'Accessibility mode for view',
                    'Accessibility mode for download': 'Accessibility mode for download',
                    'Approve card': 'Approve card',
                    'Change': 'Change',
                    'File deleted': 'File deleted',
                    'File upload': 'File upload',
                    'Card status': 'Card status',
                    'Unit': 'Unit',
                    'Normal': 'Normal',
                    'Draft': 'Draft',
                    'Deleted': 'Deleted',
                    'Censored': 'Censored',
                    'Agreed': 'Agreed',
                    'Legal Entity': 'Legal Entity',
                    'Firstname is required': 'Firstname is required',
                    'Lastname is required': 'Lastname is required',
                    'Company is required': 'Company is required',
                    'Phone is required': 'Phone is required',
                    'Autofill': 'Autofill',
                    'Exit': 'Exit',
                    'Reply': 'Reply',
                    'Access': 'Access',
                    'Offers': 'Offers',
                    'Name': 'Name',
                    'Contact': 'Contact',
                    'Organization': 'Organization',
                    'Organizations': 'Organizations',
                    'Lastname': 'Lastname',
                    'Partner': 'Partner',
                    'Organization name': 'Organization name',
                    'Permission': 'Permission',
                    'Permissions': 'Permissions',
                    'Created': 'Created',
                    'Updated': 'Updated',
                    'Details': 'Details',
                    'Account name': 'Account name',
                    'About': 'About',
                    'Groups': 'Groups',
                    'Taxnum': 'Taxnum',
                    'Choose a organization': 'Choose a organization',
                }
            },
            ru: {
                account: account.ru,
                component: component.ru,
                dashboard: dashboard.ru,
                profile: profile.ru,
                modal: modal.ru,
                tv: tv.ru,
                group: group.ru,
                order: order.ru,
                translations: {
                    "provider_owner": "Поставщик владелец",
                    "consumer_auditor": "Потребитель аудитор",
                    "Enter to account": "Войти в аккаунт",
                    'Accounts': 'Аккаунты',
                    'My accounts': 'Мои аккаунты',
                    'Ask our support': 'Задайте вопрос нашей поддержке',
                    'Mediabaza': 'Медиабаза',
                    'Comment': 'Комментарий',
                    'Next': 'Дальше',
                    'Cancel': 'Отменить',
                    'Transcode': 'Транскодировать',
                    'Transcoded': 'Транскодировано',
                    'Download transcoded': 'Скачать транскодированное',
                    'Download all': 'Скачать все',
                    'Email': 'Электронная почта',
                    'Enter Email': 'Введите эл. почту',
                    'Enter password': 'Введите пароль',
                    'Passwords do NOT match': 'Пароли не совпадают',
                    'Global marketplace <br>to buy and sell media content': 'Глобальный <br />маркетплейс для продавцов и&nbsp;покупателей медиаконтента',
                    'Unique content': 'Уникальный контент',
                    'Piracy Protection': 'Защита от&nbsp;пиратства',
                    'Free access to basic functions': 'Бесплатный доступ к&nbsp;основным функциям',
                    'Affordable price': 'Доступные цены',
                    'User friendly support': 'Дружественная клиентская поддержка',
                    'Content Search': 'Поиск контента',
                    'Unique catalog of downloadable content and TV channels for easy search and order': 'Уникальный каталог загружаемого контента для удобного поиска и&nbsp;заказа',
                    'Licensing Rights': 'Передача прав',
                    'Safe transactions between a licensor and a licensee. Highly automated processes': 'Безопасные сделки между лицензиатами и&nbsp;лицензиарами. Высокая автоматизация процедур',
                    'Delivery': 'Доставка',
                    'Reliable content delivery of heavy-loaded video content in innovative formats VR, 4К, 8К, HDR': 'Надежная доставка тяжело-загружаемого видеоконтента в&nbsp;инновационных форматах VR, 4К, 8К, HDR',
                    'Updating': 'Обновление',
                    '<br />catalog of streaming services': '<br/>каталогов интернет-кинотеатров',
                    'Distributing': 'Распространение',
                    '<br />ad-videos to the regional network': '<br />рекламных роликов по&nbsp;региональной сети',
                    'Uploading': 'Загрузка',
                    'movies to multiplex movie theaters': 'фильмов в&nbsp;многозальные кинотеатры',
                    'Setting content': 'Программное наполнение',
                    'for TV channels': 'телеканалов',
                    'Cloud platform to increase the efficiency of interactions between professional participants in the media market': 'Облачная платформа для повышения эффективности взаимодействия профессиональных участников медиарынка',
                    'For Sellers': 'Для продавцов',
                    'Sell Сontent': 'Продать контент',
                    'Single entry point to reach your customers': 'Единая точка входа к своим покупателям',
                    'Safe storage of content archive': 'Безопасное хранение архива контента',
                    'Fast delivery of heavy 4K content': 'Быстрая доставка тяжелого 4К контента',
                    'Content distribution control': 'Контроль распространения контента',
                    'Lead generation': 'Генерация лидов',
                    'For Buyers': 'Для покупателей',
                    'Buy Сontent': 'Купить контент',
                    'Easy to use catalog to search for content from all over the world': 'Удобный каталог для поиска нового контента со всего мира',
                    'Easy content ordering': 'Простая процедура заказа контента',
                    'Video transcoding into any format': 'Преобразование видео в необходимые форматы',
                    'Downloading using speed protocol': 'Загрузка с использованием скоростного протокола',
                    'Metadata is included': 'Сопровождение контента пакетом метаинформации',
                    'Get free access to try out the platform': 'Получите бесплатный доступ, чтобы испытать сервис',
                    'Get Access': 'Запросить доступ',
                    'Feedback': 'Обратная связь',
                    'Contacts': 'Контакты',
                    'To showcase': 'К витрине',
                    'Download': 'Скачать',
                    'Download again': 'Скачать повторно',
                    'Create': 'Создать',
                    'Send': 'Отправить',
                    'Content unit': 'Загружаемый контент',
                    'TV channel': 'Телеканал',
                    'Create a new card': 'Создать новую карточку',
                    'Create a content unit': 'Создание единицы контента',
                    'Create a tv unit': 'Создание карточки телеканала',
                    '+ Upload files': '+ Загрузить файлы',
                    'Enter a title': 'Укажите название',
                    'Enter a name': 'Укажите имя',
                    'Enter license number': 'Укажите номер лицензии',
                    'Title': 'Название',
                    'optional': 'необязательно',
                    'required': 'обязательно',
                    'Load cover': 'Загрузите обложку',
                    'Load demo': 'Загрузите демо',
                    'Choose a category': 'Выберите категорию',
                    'Fill in the information': 'Заполните информацию',
                    'Choose a country': 'Выберите страну',
                    'Choose a language': 'Выберите язык',
                    'Choose a genre': 'Выберите жанр',
                    'Choose a collection': 'Выберите коллекцию',
                    'Country of Origin': 'Страна производства',
                    'Voice language': 'Язык озвучения',
                    'Genre': 'Жанр',
                    'Subtitle': 'Субтитры',
                    'Choose a year': 'Выберите год',
                    'Year of issue': 'Год выпуска',
                    'Description': 'Описание',
                    'Enter a description': 'Введите описание',
                    'Quality': 'Качество',
                    'Choose a quality': 'Выберите качество',
                    'Choose an accessibility mode': 'Выберите режим доступности',
                    'To view': 'Для просмотра',
                    'To download': 'Для скачивания',
                    'Choose a role': 'Выберите роль',
                    'Choose a specialization': 'Выберите специализацию',
                    'Choose a customers': 'Выберите потребителей',
                    'Choose a customers to view': 'Выберите потребителей для просмотра',
                    'Choose a customers to download': 'Выберите потребителей для скачивания',
                    'Offer consumers': 'Предложите потребителям',
                    'All': 'Все',
                    'List': 'Список',
                    'Nobody': 'Никто',
                    'Drag file here': 'Перетащите сюда файл',
                    'Drag files here': 'Перетащите сюда файлы',
                    'or select on computer': 'или выберите на компьютере',
                    'Supported file formats mp4, webm и ogg': 'Поддерживаемые форматы mp4, webm и ogg',
                    'Page not found': 'Страница не найдена',
                    'You can go ': 'Вы можете вернуться ',
                    'back': 'назад',
                    ' or go to the ': ' или перейти в ',
                    'showcase': 'каталог',
                    'Sign': 'Вход',
                    'Log In': 'Вход',
                    'Sign in': 'Войти',
                    'Sign Up': 'Регистрация',
                    'Forgot password': 'Забыли пароль',
                    'Forgot your password': 'Забыли пароль',
                    'Register': 'Регистрация',
                    'Tools': 'Инструменты',
                    'Load into xls': 'Выгрузить в xls',
                    'Avatar': 'Аватар',
                    'title or title_eng must be specified': 'Название не должно быть пустым',
                    'Enter a techinfo': 'Введите параметры',
                    'Message is required': 'Введите сообщение',
                    'Email is required': 'Укажите эл. почту',
                    'Code is required': 'Укажите код из письма',
                    'Invalid code': 'Код должен состоять из 6 цифр',
                    'Files': 'Файлы',
                    'Load error': 'Ошибка загрузки',
                    'Delete request error': 'Ошибка удаления заявки',
                    'Change permissions error': 'Ошибка изменения прав',
                    'Save failed': 'Ошибка сохранения',
                    'Delete failed': 'Ошибка удаления',

                    'This password is too short. It must contain at least 8 characters.': 'Введённый пароль слишком короткий. Он должен содержать как минимум 8 символов.',
                    'This password is too common.': 'Введённый пароль слишком широко распространён.',
                    'This password is entirely numeric.': 'Введённый пароль состоит только из цифр.',
                    'Your password can’t be too similar to your other personal information.': 'Пароль не должен быть слишком похож на другую вашу личную информацию.',
                    'Your password can’t be a commonly used password.': 'Пароль не должен быть слишком простым и распространенным.',
                    'Your password can’t be entirely numeric.': 'Пароль не может состоять только из цифр.',

                    'Delivered': 'Доставлено',
                    'Handled': 'Обработано',
                    'ALL': 'Всего',
                    'MY': 'Мои',
                    'CUSTOM': 'Список',
                    'NOTHING': 'Никому',
                    'Notification list': 'Уведомления',
                    'View Permissions': 'Права просмотра',
                    'Download Permissions': 'Права скачивания',
                    'Novelty': 'Новинки',
                    'Admin dashboard': 'Панель администратора',
                    'Consumer dashboard': 'Панель потребителя',
                    'Provider dashboard': 'Панель поставщика',
                    'Add': 'Добавить',
                    'Edit': 'Изменить',
                    'Delete': 'Удалить',
                    'Deleted': 'Удалено',
                    'Success': 'Успешно',
                    'Error': 'Ошибка',
                    'View': 'Смотреть',
                    'Save': 'Сохранить',
                    'Back': 'Назад',
                    'Check': 'Проверить',
                    'Code': 'Код',
                    'Status': 'Статус',
                    'Refresh': 'Обновить',
                    'Confirm': 'Подтвердить',
                    'Settings': 'Настройки',
                    'Support': 'Поддержка',
                    'FAQ': 'ЧаВо',
                    'Account': 'Аккаунт',
                    'Tariff': 'Тариф',
                    'Statistics': 'Статистика',
                    'Downloads': 'Скачивания',
                    'Help': 'Помощь',
                    'Quick add': 'Добавить быстро',
                    'No Data': 'Нет данных',
                    'Off': 'Отключить',
                    'Set user': 'Стать пользователем',
                    'Yes': 'Да',
                    'No': 'Нет',
                    'Token': 'Токен',
                    'Grant': 'Разрешить',
                    'Reject': 'Отказать',
                    'Owner': 'Владелец',
                    'Cover': 'Обложка',
                    'Order': 'Заказать',
                    'Catalog': 'Каталог',
                    'Notifications': 'Уведомления',
                    'To list': 'К списку',
                    'Access requests': 'Заявки на доступ',
                    'Access request': 'Заявка на доступ',
                    'Request permission': 'Запросить разрешение',
                    'Repeat password': 'Повтор пароля',
                    'Enter email for verification': 'Введите email для проверки',
                    'Enter code for': 'Введите код для',
                    'Send again': 'Отправить еще раз',
                    'Requests for registration': 'Заявки на регистрацию',
                    'confirmPre': 'Подтвердите форму предварительной регистрации для <1>{{email}}</1>',
                    'The code is valid for': 'Код действителен еще',
                    'second': 'секунд',
                    'Re-request code after': 'Перезапросить код через',
                    'Reset password': 'Запрос на сброс пароля',
                    'Change password': 'Изменить пароль',
                    'Activate': 'Активировать',
                    'Activate account': 'Активация аккаунта',
                    'Email already registered': 'Email уже зарегистрирован',
                    'Confirmation code is wrong': 'Неверный код подтверждения',
                    'Old password': 'Старый пароль',
                    'New password': 'Новый пароль',
                    'Content unit information': 'Информация о единице контента',
                    'Token is invalid or expired': 'Токен неверен либо просрочен',
                    'Password successfully changed': 'Пароль успешно изменен',
                    'Wait for the letter to the specified email and follow the link': 'Дождитесь письма на узазанный email и перейдите по ссылке',
                    "Enter your username and password or apply for registration": "Введите логин и пароль или подайте заявку на регистрацию",
                    'To complete the registration, wait for confirmation from the administrator and follow the link in the letter sent to you': 'Для окончания регистрации дождитесь подтверждения от администратора и перейдите по сслылке в отправленном вам письме',
                    'AfterRegister1': 'Спасибо за заполнение заявки  на подключение к нашему сервису.',
                    'AfterRegister2': 'Сервис работает в тестовом режиме, поэтому срок рассмотрения может составить до 2 рабочих дней.',
                    'AfterRegister3': 'При необходимости уточнить данные мы свяжемся с Вами по телефону, указанному в заявке.',
                    'AfterRegister4': 'О результатах рассмотрения заявки мы сообщим по электронной почте.',
                    'Contact Support': 'Обратиться в поддержку',
                    "Main": 'На главную',
                    'Fill out the pre-registration form for': 'Заполните форму предварительной регистрации для',
                    "Users": 'Пользователи',
                    "User": 'Пользователь',
                    "Firstname": 'Имя',
                    "Surname": 'Отчество',
                    "Company": 'Компания',
                    "Phone": 'Телефон',
                    "Role": 'Роль',
                    "administrators": 'Администратор',
                    "administrator": 'Администратор',
                    "consumer": 'Потребитель',
                    "Consumer": 'Потребитель',
                    "Consumers": 'Потребители',
                    "Subscribers": 'Потребители',
                    "consumers": 'Потребитель',
                    "provider": 'Поставщик',
                    "Provider": 'Поставщик',
                    "providers": 'Поставщик',
                    "Moderator": 'Модератор',
                    "Moderators": 'Модераторы',
                    "moderators": 'Модератор',
                    "moderator": 'Модератор',
                    'Is active': 'Активен',
                    'Is locked': 'Заблокирован',
                    'Joined': 'Дата регистрации',
                    'Last login': 'Последний вход',
                    'Profile': 'Профиль',
                    'Content': 'Контент',
                    'Requests': 'Заявки',
                    'Orders': 'Заявки',
                    'Login': 'Логин',
                    'Password': 'Пароль',

                    'Apply for registration': 'Подать заявку на регистрацию',
                    'Trailer': 'Трейлер',
                    'Trailer exists': 'Трейлер загружен',
                    'Type': 'Тип',
                    'Collection': 'Коллекция',
                    'Year': 'Год',
                    'Language': 'Язык',
                    'Country': 'Страна',
                    'Techinfo': 'Тех. инфо',
                    'Links': 'Связи',
                    'Tags': 'Теги',
                    'List of content': 'Список контента',
                    'IMDb': 'IMDb',
                    'Kinopoisk': 'Кинопоиск',
                    '{0} selected': '{0} выбрано',
                    'Image type not supported': 'Тип изображения не поддерживается',
                    'Content is not available': 'Контент недоступен',
                    'No rights to view': 'Отсутствуют права на просмотр',
                    'Showcase': 'Витрина',
                    'Consumers list': 'Список потребителей',
                    'Search': 'Искать',
                    'Permited': 'Разрешено',
                    'Not permited': 'Запрещено',
                    'Meta': 'Мета',
                    'Show filter': 'Показать фильтр',
                    'Hide filter': 'Спрятать фильтр',
                    'Nothing found': 'Ничего не найдено',
                    'Messages': 'Сообщения',
                    'Message': 'Сообщение',
                    'Download manager': 'Менеджер загрузок',
                    'Media': 'Медиа',
                    'Upload': 'Загрузить',
                    "block": "Заблокировать",
                    "unblock": "Разблокировать",
                    'ORPHAN_CONTENT': 'Для добавления контента, сначала сохраните название карточки',
                    'ORPHAN_TRAILER': "Для добавления демо ролика, сначала сохраните название карточки",
                    'ORPHAN_COVER': 'Для добавления обложки, сначала сохраните название карточки',
                    'Orders filter all': 'Все',
                    'Orders filter new': 'Новые',
                    'Orders filter pending': 'Ожидание',
                    'Orders filter granted': 'Доступ разрешён',
                    'Orders filter rejected': 'Не договорились',
                    'Orders filter archived': 'Архив',
                    'Orders filter date': 'Дата заявки',
                    'Orders filter date placeholder': 'Выберите дату',

                    'Orders filter producer': 'Поставщик',
                    'Orders filter consumer': 'Потребитель',

                    'Orders status open': 'Ожидает',
                    'Orders status pending': 'На рассмотрении',
                    'Orders status granted': 'Разрешено',
                    'Orders status rejected': 'Не договорились',

                    'Orders operation open': 'Выберите статус',
                    'Orders operation pending': 'На рассмотрении',
                    'Orders operation grant': 'Разрешить',
                    'Orders operation reject': 'Отказано',
                    'Orders operation download': 'Скачать',
                    'Orders operation reply': 'Ответить',
                    'Orders operation write': 'Написать',

                    'Orders xls export': 'Выгрузить в xls',

                    'Orders table producer': 'Поставщик',
                    'Orders table consumer': 'Потребитель',
                    'Orders table status': 'Статус',
                    'Orders table content': 'Контент',
                    'Orders table date': 'Дата и время',

                    'FAQ title': 'Часто задаваемые вопросы (FAQ)',
                    'Feedback your message': 'Ваше сообщение',
                    'Feedback put message': 'Оставьте сообщение',
                    'Feedback email': 'Эл. почта',
                    'Feedback put email': 'Укажите эл. почту',
                    'Feedback sent': 'Заявка отправлена',
                    'Feedback hide': 'Хорошо',

                    'Contacts msk-ix': 'АО «ЦВКС «МСК-IX»',
                    'Contacts msk-ix long': 'Акционерное общество «Центр взаимодействия компьютерных сетей «МСК-IX»',
                    'Contacts connect': 'Подключение и поддержка',
                    'Contacts press': 'Пресс-служба',
                    'Contacts administration': 'Администрация',

                    'Error message title': 'Что-то пошло не так',
                    'Error message confirm': 'Понятно',
                    'Success message close': 'Хорошо',

                    'Header menu support': 'Поддержка',
                    'SHORT_SECOND': 'с',
                    'Date': 'Дата',
                    'Demo': 'Демо',
                    'You have unsaved canges in content card. Continue?': 'У вас остались несохраненные изменения в карточке контента. Вы действительно хотите выйти?',
                    'Ordered': 'Заказано',
                    'rejected': 'Не договорились',
                    'Rejected': 'Не договорились',
                    'Granted': 'Одобрено',
                    'granted': 'Одобрено',
                    'unknown': 'Неизвестно',
                    'Change subscriber status error': 'Ошибка изменения статуса потребителя',
                    'Back from view mode': 'Выйти из режима просмотра, как потребитель',
                    'Meta': 'Мета',
                    'Category': 'Категория',
                    'Territory': 'Территория',
                    'Type of rights': 'Тип прав',
                    'Accessibility mode': 'Выберите режим доступности',
                    'Accessibility mode for view': 'Режим доступности для просмотра',
                    'Accessibility mode for download': 'Режим доступности для скачивания',
                    'Approve card': 'Согласовать карточку',
                    'History of changes': 'История изменений',
                    'Creation': 'Создание',
                    'Change': 'Изменение',
                    'File deleted': 'Удалён файл',
                    'File upload': 'Загружен файл',
                    'Card status': 'Статус карточки',
                    'Unit': 'Карточка',
                    'Normal': 'Нормально',
                    'Draft': 'Черновик',
                    'Deleted': 'Удалено',
                    'Censored': 'Заблокировано',
                    'Agree': 'Согласование',
                    'Agreed': 'Согласовано',
                    'Legal Entity': 'Название организации',
                    'Firstname is required': 'Укажите имя',
                    'Lastname is required': 'Укажите фамилию',
                    'Company is required': 'Укажите название',
                    'Phone is required': 'Укажите номер телефона',
                    'Autofill': 'Заполните за меня',
                    'Exit': 'Выход',
                    'Reply': 'Ответить',
                    'Access': 'Заявки',
                    'Offers': 'Предложения',
                    'Name': 'Имя',
                    'Contact': 'Контакт',
                    'Organization': 'Организация',
                    'Organizations': 'Организации',
                    'Lastname': 'Фамилия',
                    'Partner': 'Партнер',
                    'Organization name': 'Название организации',
                    'Permission': 'Разрешение',
                    'Permissions': 'Разрешения',
                    'Created': 'Создано',
                    'Updated': 'Изменено',
                    'Details': 'Детали',
                    'Account name': 'Название аккаунта',
                    'About': 'О нас',
                    'Groups': 'Группы',
                    'Taxnum': 'ИНН',
                    'Choose a organization': 'Выберите организацию',
                }
            }
        },
        fallbackLng: "en",
        debug: process.env.NODE_ENV !== 'production',

        // have a common namespace used around the full app
        ns: ["translations", 'modal', 'dashboard', 'profile'],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
