import { createContext, Dispatch, SetStateAction } from 'react'
import { UnitUpload } from '../../services/content_v1';

export interface UploaderConfig {
    tries: number
    concurrent: number
    retryWait: number
    chunkSize: number
}

export interface UploadFilesContextType {
    uploadFiles: { [key: string]: UnitUpload }
    setUploadingFiles: Dispatch<SetStateAction<{ [key: string]: UnitUpload }>>
    config: UploaderConfig
}

const context = {
    uploadFiles: {},
    setUploadingFiles: () => ({}),
    uploadRunning: false,
    config: {
        tries: 3,
        concurrent: 1,
        retryWait: 1000,
        chunkSize: 2 * 1024 * 1024,
    }
}

export const UploadFilesContext = createContext<UploadFilesContextType>(context)
