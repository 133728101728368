import { HTMLAttributes } from 'react';

interface SectionProps extends HTMLAttributes<HTMLParagraphElement> {
    label: string | React.ReactNode,
    icon?: JSX.Element,
    hint?: string,
    children?: React.ReactNode;
}

function Section({ label, icon, hint, children, ...props }: SectionProps) {
    return (
        <section className="form__section" {...props}>
            <h3 className="form__section-heading">
                {icon} {label} <span>{hint}</span>
            </h3>
            {children}
        </section>
    );
}

export default Section;