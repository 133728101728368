
interface FeatureItem {
    icon: string,
    label: string,
    content: string
}

interface HomeFeatures2Props {
    children?: React.ReactNode;
}

interface HomeFeatureItem2Props {
    item: FeatureItem,
}

export function HomeFeatureItem2({ item }: HomeFeatureItem2Props) {
    return (
        <div className="home-features2__col">
            <article className="home-features2__item">
                <p className="home-features2__item-image">
                    <img src={item.icon} alt="" /></p>
                <p className="home-features2__item-copy">
                    <strong>{item.label}</strong> <span dangerouslySetInnerHTML={{__html: item.content}}></span>
                </p>
            </article>
        </div>
    )
}

function HomeFeatures2({ children }: HomeFeatures2Props) {
    return (
        <section className="home-features2">
            {children}
        </section>
    )
}

export default HomeFeatures2;