import { DatePicker } from './DatePicker';

interface MobileDateFilterItemProps {
    value?: Date,
    label: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: (value: any) => void,
    placeholder: string,
}

export function MobileDateFilterItem({ value, label, setValue, placeholder }: MobileDateFilterItemProps) {
    return (
        <div className="mobile-filter__item _calendar">
            <div className="mobile-filter__item-name">
                {label}
            </div>
            <DatePicker value={value} placeholder={placeholder} setValue={setValue} />
        </div>
    );
}