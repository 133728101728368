import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Content from '../components/mplat/Content';

function NotFound() {
    return (
        <div className="e404">
            <div className="e404__code">404</div>
            <div className="e404__heading">
                <Trans>Page not found</Trans>
            </div>
            <p className="e404__copy">
                <Trans>You can go </Trans>
                {/* eslint-disable-next-line, no-script-url */}
                <a href="#back" onClick={() => window.history.back()}>
                    <Trans>back</Trans>
                </a>
                <Trans> or go to the </Trans>
                <Link to="/content">
                    <Trans>showcase</Trans>
                </Link>
            </p>
        </div>
    );
}

function NotFoundPage() {
    return (
        <Content>
            <NotFound />
        </Content>
    );
}

export default NotFoundPage;
