import { ChangeEvent, useEffect, useRef } from 'react';
import { useOnScreen } from '../../hooks';
import Label from './Label';

interface TextInputProps {
    value?: string;
    placeholder?: string,
    required?: boolean,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    error?: string,
    type?: string
    disabled?: boolean,
    label?: string,
    className?: string,
    'data-test-id'?: string,
}

function TextInput({ value, placeholder, required, onChange, error, type, disabled, label, 'data-test-id': testId, className }: TextInputProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const isVisible = useOnScreen(inputRef);
    const localType = type ? type : 'text';
    const cls = `form__item ${className || ''}`;

    useEffect(() => {
        if (error && !isVisible) {
            const input = inputRef.current;
            const top = input?.offsetTop || 0;
            window.scrollTo(0, top - 40);
        }
        // eslint-disable-next-line
    }, [error]);

    return (
        <>
            {label && <Label>{label}</Label>}
            <div className={cls}>
                <input
                    ref={inputRef}
                    type={localType}
                    className={'form__input' + ((error && (!value && required)) ? ' _error' : '')}
                    required={required}
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => { onChange && onChange(e); }}
                    disabled={disabled}
                    data-test-id={testId}
                />
                {(error && (!value && required)) && <span className="form__input-error-message">{error}</span>}
            </div>
        </>
    );
}

export default TextInput;