import { SmartCaptcha } from '@yandex/smart-captcha';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth, useLanguage } from '../../../hooks';
import { useSendFeedbackMutation, useSendToSupportMutation } from '../../../services/api';
import { extractErrorDetail } from '../../common';
import { Modal } from '../Modal';
import Form from '../Form';
import Button from '../Button';

interface FeedbackModalProps {
    isOpen: boolean,
    onClose: () => void,
}

export function FeedbackModal({ isOpen, onClose }: FeedbackModalProps) {
    const [sendMessage] = useSendFeedbackMutation();
    const [sendToSupport] = useSendToSupportMutation();
    const auth = useAuth();
    const lang = useLanguage();

    const [success, setSuccess] = useState<boolean>();
    const [fail, setFail] = useState<string>();

    const [token, setToken] = useState('');

    const defaultEmail = {
        email: '',
        text: '',
    };
    const { control, reset, formState: { errors }, handleSubmit } = useForm({ defaultValues: defaultEmail });

    const successClass = 'popup_message';
    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen) {
            setSuccess(false);
            setFail('');
        }
    }, [isOpen]);

    const getFormErrorMessage = (name: keyof typeof errors): JSX.Element | undefined => {
        const e = errors[name];
        return e && <div className="form__input-error-message"><Trans>{e.message}</Trans></div>
    };

    // <div className="form-message__copy">
    //     После проверки данных, наш администратор отправит вам на&nbsp;почту информацию для авторизации</div>
    return (
        <Modal className={success ? successClass : 'popup_feedback'} isOpen={isOpen} onClose={() => onClose()}>
            {success ?
                <div className="form-message _success">
                    <div className="form-message__icon"></div>
                    <h2 className="popup__heading form-message__heading"><Trans>Feedback sent</Trans></h2>
                    <div className="form-message__ok">
                        <span className="btn" onClick={() => onClose()}><Trans>modal:SUCCESS_MESSAGE_CONFIRM</Trans></span>
                    </div>
                </div>
                :
                (fail
                ?
                    <div className="form-message _error">
                        <div className="form-message__icon"></div>
                        <h2 className="popup__heading form-message__heading"><Trans>modal:ERROR_MESSAGE_TITLE</Trans></h2>
                        <div className="form-message__copy">
                            {fail}
                        </div>
                        <div className="form-message__ok">
                            <span className="btn" onClick={() => onClose()}><Trans>modal:ERROR_MESSAGE_CONFIRM</Trans></span>
                        </div>
                    </div>
                    :
                    <Form action="#" className="form-feedback" id="feedback">
                        <h2 className="popup__heading">
                            <Trans>Feedback</Trans>
                        </h2>
                        <Controller name="text" control={control} rules={{ required: 'Message is required' }} render={({ field, fieldState }) => (
                            <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                                <textarea id={field.name} {...field} placeholder={t('Feedback your message')} autoFocus className={'form__input _required' + (fieldState.error ? ' _error' : '')} />
                                {getFormErrorMessage('text')}
                            </div>
                        )} />
                        {auth.user
                        ?
                            <>
                                <div className="form__submit" style={{ marginTop: '20px' }}>
                                    <Button onClick={handleSubmit((data) => {
                                        sendToSupport(data.text).unwrap().then(() => {
                                            setSuccess(true);
                                            reset();
                                        }).catch(e => {
                                            setFail(extractErrorDetail(e));
                                        })
                                    })}>
                                        <Trans>
                                            Send
                                        </Trans>
                                    </Button>
                                </div>
                            </>
                            :
                            <>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'modal:INVALID_EMAIL'
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                                            <input id={field.name} {...field} placeholder={t('Email')} autoFocus className={'form__input _required' + (fieldState.error ? ' _error' : '')} />
                                            {getFormErrorMessage('email')}
                                        </div>
                                    )}
                                />
                                <SmartCaptcha language={lang} sitekey="ysc1_CGbTGnEniUByIpNrHaWkRb2DEfaigOpUTJY0PFNc79bbb80c" onSuccess={setToken} />

                                {token && <div className="form__submit" style={{ marginTop: '20px' }}>
                                    <Button onClick={handleSubmit((data) => {
                                        sendMessage({ email: data.email, text: data.text, token }).unwrap().then(() => {
                                            setSuccess(true);
                                            reset();
                                        }).catch(e => {
                                            setFail(extractErrorDetail(e));
                                        })
                                    })}>
                                        <Trans>
                                            Send
                                        </Trans>
                                    </Button>
                                </div>}
                            </>
                        }
                    </Form>
                )
            }
        </Modal >
    );
}