import { useState } from 'react';
import { EmailValidationModal } from '../Modals/EmailValidationModal';

interface PropsItem {
    icon: string,
    label: string,
    content: string[]
}

interface HomePropsProps {
    label: string,
    children?: React.ReactNode;
}

interface HomePropsItemProps {
    item: PropsItem,
    buttonTitle?: string
}

interface HomePropsBodyImageProps {
    image: string,
}

export function HomePropsItem({ item, buttonTitle }: HomePropsItemProps) {
    const [showRegistration, setShowRegistration] = useState(false);

    return (
        <article className="home-pros__item">
            <EmailValidationModal isOpen={showRegistration} onClose={() => setShowRegistration(false)} />
            <h2 className="home-pros__item-heading">
                <img src={item.icon} alt="" />
                {item.label}
            </h2>
            <ul className="home-pros__item-list">
                {item.content.map(row => <li key={row} dangerouslySetInnerHTML={{ __html: row }} />)}
            </ul>
            {buttonTitle && <p className="home-pros__item-cta">
                <a
                    href="#register"
                    className="btn _js-request"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowRegistration(true);
                    }}>{buttonTitle}</a>
            </p>}
        </article>
    );
}

export function HomePropsBodyImage({ image }: HomePropsBodyImageProps) {
    return (
        <div className="home-pros__body-image">
            <img src={image} alt="" />
        </div>
    );
}

function HomeProps({ label, children }: HomePropsProps) {
    return (
        <section className="home-pros">
            <h2 className="home-pros__heading">{label}</h2>
            <div className="home-pros__body">
                {children}
            </div>
        </section>
    )
}

export default HomeProps;