import { changeLanguage } from 'i18next';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROLE, useIsProvider } from '../components/common';
import Content from '../components/mplat/Content';
import { useAuth, useBodyClass, useRole } from '../hooks';
import { useAccountLoginMutation } from '../services/api';
import { AccountShort, useLazyGetAccountLoginQuery } from '../services/users_v1';


interface AccountProps {
    item: AccountShort
}

function Account({ item }: AccountProps) {
    const [accountLogin] = useAccountLoginMutation();
    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [error, setError] = useState('');
    
    return (
        <article className="contacts__list-item" style={{cursor: 'pointer'}} title={t('Enter to account')} onClick={() => {
            accountLogin({
                refresh: auth.refreshToken,
                username: auth.user,
                account: item.id.toString()
            }).unwrap().then(() => {
                // TODO убрать после нормального перевода
                changeLanguage('ru');
                window.location.pathname === '/select-accounts' && navigate('/');
            }).catch((e) => {
                setError(e.message);
            });
        }}>
            <div className="contacts__list-item-image">
                {item.logo}
            </div>
            <h2 className="contacts__list-item-name">{item.name}</h2>
            <p className="contacts__list-item-copy">
                {error}
            </p>
        </article>
    );
}

interface AccountsListProps {
    list: AccountShort[];
}

function AccountsList({ list }: AccountsListProps) {
    return (
        <div className="contacts__list">
            {list.map(item => <Account key={item.id} item={item} />)}
        </div>
    );
}

interface ContactsProps {
    children?: React.ReactNode;
}

function Accounts({ children }: ContactsProps) {
    return (
        <div className="contacts">
            {children}
        </div>
    );
}

function AccountsHeading() {
    return (
        <h1 className="contacts__heading">
            <Trans>Accounts</Trans>
        </h1>
    );
}

function AccountsName() {
    return (
        <p className="contacts__name">
            <strong></strong><br />
        </p>
    );
}

function AccountSelectionPage() {
    const auth = useAuth();
    const role = useRole();
    const dispatch = useDispatch();
    const [bodyClasses, setBodyClasses] = useState<string[]>([]);
    const [fetchAccounts, {data: accounts}] = useLazyGetAccountLoginQuery();

    const isProvider = useIsProvider();

    // const accounts: AccountShort[] = useSelector((state: RootState) => state.auth.accounts);

    useEffect(() => {
        fetchAccounts();
    }, []);

    useEffect(() => {
        const tmp: string[] = [];
        if (auth.account) {
            if (isProvider || role.label === ROLE.SITE_ADMIN) {
                tmp.push('page-producer');
            } else {
                tmp.push('page-subscriber');
            }
        } else {
            tmp.push('page-home');
            tmp.push('page-subscriber');
        }
        setBodyClasses(tmp);
    }, [auth.account, dispatch, role.label]);

    useBodyClass(bodyClasses);

    return (
        <Content>
            <Accounts>
                <AccountsHeading />
                <AccountsName />
                <AccountsList list={accounts?.results || []} />
            </Accounts>
        </Content>
    )
}

export default AccountSelectionPage;
