import { CSSProperties, HTMLAttributes } from 'react';

interface MainHeaderProps extends HTMLAttributes<HTMLElement> {
    label: string,
    icon?: JSX.Element,
    children?: React.ReactNode;
    h1Styles?: CSSProperties 
}

function MainHeader({ label, icon, children, h1Styles, ...props }: MainHeaderProps) {
    return (
        <header className="main__header" {...props}>
            <h1 className="main__header-heading" style={h1Styles}>
                {icon} {label}
            </h1>
            {children}
        </header>
    );
}

export default MainHeader;
