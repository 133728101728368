import { HTMLAttributes } from 'react';
import { useIsConsumer } from '../common';

interface ContentShadowProps extends HTMLAttributes<HTMLParagraphElement> {
    children?: React.ReactNode;
}

function ContentShadow({ children, ...props }: ContentShadowProps) {
    const isConsumer = useIsConsumer();
    return isConsumer ?
        <div className="content__shadow" {...props}>
            {children}
        </div>
        :
        <div />;
}

export default ContentShadow;
