import { useTranslation } from 'react-i18next';
import HomeAuth from '../components/mplat/Home/HomeAuth';
import HomeIntro from '../components/mplat/Home/HomeIntro';
import HomeTry from '../components/mplat/Home/HomeTry';
import HomeFeatures, { HomeFeatureItem } from '../components/mplat/Home/HomeFeatures';
import HomeFeatures2, { HomeFeatureItem2 } from '../components/mplat/Home/HomeFeatures2';
import HomeProps, { HomePropsBodyImage, HomePropsItem } from '../components/mplat/Home/HomeProps';
import Delivery from '../assets/img/home/features-delivery.svg';
import Distrib from '../assets/img/home/features-distrib.svg';
import Programms from '../assets/img/home/features-programms.svg';
import Rights from '../assets/img/home/features-rights.svg';
import Search from '../assets/img/home/features-search.svg';
import Updates from '../assets/img/home/features-updates.svg';
import Upload from '../assets/img/home/features-upload.svg';
import Buyers from '../assets/img/home/icon-buyers.svg';
import Sellers from '../assets/img/home/icon-sellers.svg';
import Pros from '../assets/img/home/pros-image.svg';
import HomeContent from '../components/mplat/Home/HomeContent';
import { useEffect } from 'react';

function IndexPage() {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('Mediabaza');
    }, [t]);

    const labelIntro = t('Global marketplace <br>to buy and sell media content');

    const introBages = [
        t('Unique content'),
        t('Piracy Protection'),
        t('Free access to basic functions'),
        t('Affordable price'),
        t('User friendly support'),
    ];

    const features = [
        {
            icon: Search,
            label: t('Content Search'),
            content: t('Unique catalog of downloadable content and TV channels for easy search and order')
        },
        {
            icon: Rights,
            label: t('Licensing Rights'),
            content: t('Safe transactions between a licensor and a licensee. Highly automated processes')
        },
        {
            icon: Delivery,
            label: t('Delivery'),
            content: t('Reliable content delivery of heavy-loaded video content in innovative formats VR, 4К, 8К, HDR')
        },
    ]

    const featureItems = [
        {
            icon: Updates,
            label: t('Updating'),
            content: t('<br />catalog of streaming services')
        },
        {
            icon: Distrib,
            label: t('Distributing'),
            content: t('<br />ad-videos to the regional network')
        },
        {
            icon: Upload,
            label: t('Uploading'),
            content: t('movies to multiplex movie theaters')
        },
        {
            icon: Programms,
            label: t('Setting content'),
            content: t('for TV channels')
        },
    ];

    const propsLabel = t('Cloud platform to increase the efficiency of interactions between professional participants in the media market');
    const props = [
        {
            icon: Sellers,
            label: t('For Sellers'),
            content: [
                t('Single entry point to reach your customers'),
                t('Safe storage of content archive'),
                t('Fast delivery of heavy 4K content'),
                t('Content distribution control'),
                t('Lead generation'),
            ]
        },
        {
            icon: Buyers,
            label: t('For Buyers'),
            content: [
                t('Easy to use catalog to search for content from all over the world'),
                t('Easy content ordering'),
                t('Video transcoding into any format'),
                t('Downloading using speed protocol'),
                t('Metadata is included'),
            ]
        },
    ]

    return (
        <HomeContent>
            <HomeAuth />

            <HomeIntro
                label={labelIntro}
                badges={introBages}
            />
            <HomeFeatures>
                {features.map((item) =>
                    <HomeFeatureItem key={item.label} item={item} />
                )}
            </HomeFeatures>

            <HomeProps label={propsLabel}>
                <HomePropsItem item={props[0]} buttonTitle={t('Sell Сontent')} />
                <HomePropsBodyImage image={Pros} />
                <HomePropsItem item={props[1]} buttonTitle={t('Buy Сontent')} />
            </HomeProps>

            <HomeFeatures2>
                {featureItems.map((item) =>
                    <HomeFeatureItem2 key={item.label} item={item} />
                )}
            </HomeFeatures2>

            <HomeTry />
        </HomeContent>
    )
}

export default IndexPage;